import React, { useState, createContext, useEffect } from 'react';

export default function Login ( {setAdminLoginToken}  ) {
    const handleSubmit = function () {
        setAdminLoginToken(true);
    }
    return (
      <div className="login-wrapper">
        <h1>Please login</h1>   
        <input type="text" name="abc" />
        <button onClick={handleSubmit} type="submit">Submit</button>
      </div>
    );
}
