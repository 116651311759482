import emojis from "../emojis";
import styled from "styled-components";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "./constants";

const StyledEmoji = styled.img`
    position: absolute;
    right: -100px;
    bottom: -5px;
    opacity: 0;
    transform: scale(0.2);
    transform-origin: bottom right;
    transition: all .2s ease-in-out;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        z-index: 1000;
        right: unset;
        transform-origin: bottom left;
        left: 50%;
        bottom: -64px;
        transform: translateX(-50%);
    }
`;

function getRandomEmoji(type){
    let rand = Math.floor(Math.random() * 10);
    let emojiPath = emojis[type][rand];
    return emojiPath;
}

function RandomEmoji(props) {
    return (
        <>
        <StyledEmoji id="emoji" src={props.emojiPath} />
        </>
    );

}


export {RandomEmoji, getRandomEmoji};
