import styled from "styled-components";
import {rulesData} from "./GameRulesData";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledGameRules = styled.div`
    padding: 10px 40px 30px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 2px solid #D3D3D3;
    border-radius: 12px;
    max-width: 500px;
    margin: 0 auto;
    z-index: 9;
    transform: translate(0, -50%);
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        padding: 12px;
    }
`;

const StyledRule = styled.li`
    list-style: none;
    text-align: left;
    margin-bottom: 16px;
`

const StyledButton = styled.button`
    text-transform: uppercase;
    cursor: pointer;
    color: #fff;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    padding: 10px 30px;
    margin: 8px 1px 0 1px;
`;

function GameRules( { startFunction, showRules, gameTitle } ) {
    if (!showRules) {
        return (<div></div>);
    }
    return (
        <>
            <StyledGameRules>
                <div className="wrapper">
                    <h2 class="text-color-0">Welcome to {gameTitle}!</h2>
                    <ul>
                        {
                            rulesData.map((data, key) => {
                                return <StyledRule key={key}>{data.rule}</StyledRule>
                            })
                        }
                    </ul>
                    <hr/>
                    <p>A new puzzle is released daily! Send feedback to <br />wordthird-game@proton.me </p>
                    <StyledButton onClick={startFunction} id="start-button" className="background-color-1">PLAY!</StyledButton>
                </div>
            </StyledGameRules>
        </>
    );

}

export default GameRules;
