import styled from "styled-components";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledNav = styled.nav`
    padding: 12px 40px;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    z-index: 100;
`;

const StyledTitle = styled.h1`
    margin: 0;
    font-size: 32px;
`;

const StyledInformationButton = styled.button`
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    right: 42px;
    color: #007BC2;
    border: 2px solid #007BC2;
    font-size: 30px;
    font-weight: 800;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 42px;
    /* height: 42px; */
    background-color: #FFF;
    margin-top: 0px;
    top: 50%;
    transform: translateY(-50%) scale(1);
    transition: all 0.18s ease;
    &:hover {
        transform: translateY(-50%) scale(1.05);
    }
    &:focus {
        transform: translateY(-50%) scale(1.05);
    }
    &:active {
        margin-top: 2px;
    }
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        right: 12px;
        font-size: 16px;
        width: 36px;
    }
`

function Nav( { gameTitle, doWeShowRules, setDoWeShowRules, setHasPlayerStartedGame } ) {
  return (
  <>
    <StyledNav className="nav text-color-0 background-color-0">
        <div className="logo-container">
            <img src="/assets/img/word-third-logo-tm-larger.svg"/>
            <StyledInformationButton
                onClick={(event) => {
                    setDoWeShowRules(!doWeShowRules)
                    setHasPlayerStartedGame(true);
                    event.target.blur(); //ensures that hitting "enter" doesn't open up rule popup after typing in your guess
                }}
            >
                {doWeShowRules ? 'X' : 'i'}
            </StyledInformationButton>
        </div>
    </StyledNav>
  </>
  );
};

export default Nav;
