import styled from "styled-components";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledGameInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 15px;
`;

const StyledMagicLetterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledCorrectWordsList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 30px;
`;

const StyledTitle = styled.h2`
    margin-top:0;
    margin-bottom: -16px;
    font-weight: normal;
    font-size: 48px;
    text-transform: capitalize;
    text-align: left;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}){
        font-size: 2rem;
    }
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}){
        font-size: 1.25rem;
    }
`;

const StyledTitleLabel = styled.h4`
    margin-top: 0;
    margin-bottom: -16px;
    font-weight: normal;
    font-size: 22px;
    text-transform: capitalize;
    text-align: left;
    color: #777;
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}){
        font-size: 1rem;
    }
`

const StyledMagicLetterHeading = styled.h3`
    font-size: 22px;
    margin-top: 0px;
    `;

const StyledCorrectWordsHeading = styled.h3`
    margin-top: 0px;
    font-size: 22px;
    text-align: left;
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}){
        font-size: 1rem;
    }
`;

const StyledDivider = styled.hr`
    all: unset;
    width: 80%;
    height: 1px;
    background: #DDD;
    margin-top: 16px;
`

function GameInfo(props) {

    let todays_theme_before_heading_text = '';
    let todays_theme = '';
    let correct_words_heading_text = '';
    if ( props.hasPlayerStartedGame === true ) {
        todays_theme_before_heading_text = 'Today\'s Theme';
        todays_theme = props.todaysGame.theme;
        correct_words_heading_text = 'Correct Words';
    }

    return (
        <>
        <StyledGameInfo className="game-info text-color-0">
            <StyledTitleLabel>{todays_theme_before_heading_text}</StyledTitleLabel>
            <StyledTitle>{todays_theme}</StyledTitle>
            <StyledDivider/>
            <StyledCorrectWordsList className="correct-words-list">
                <StyledCorrectWordsHeading>{correct_words_heading_text}</StyledCorrectWordsHeading>
                <p className="correct-word" id="correctWord1"></p>
                <p className="correct-word" id="correctWord2"></p>
                <p className="correct-word" id="correctWord3"></p>
            </StyledCorrectWordsList>
        </StyledGameInfo>
        </>
    );

}

export default GameInfo;
