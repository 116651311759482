import KeyRow from "./KeyRow";
import styled from "styled-components";

const KeyBoardRow = styled.li `
    list-style: none;
    display: flex;
    justify-content: center;
`;

function Keyboard(props) {
    const rowOneletters = "qwertyuiop".split("");
    let  rowTwoletters = "asdfghjkl".split("");
    let  rowThreeletters = "zxcvbnm".split("")
    rowThreeletters.push('<=');
    rowTwoletters.push('enter');
    return (
        <ul className="keyboard">
            <KeyBoardRow className="row1"><KeyRow handleClick={props.handleClick}  letters={rowOneletters} keyboardColors={props.keyboardColors} /></KeyBoardRow>
            <KeyBoardRow className="row2"><KeyRow handleClick={props.handleClick}  letters={rowTwoletters} keyboardColors={props.keyboardColors} /></KeyBoardRow>
            <KeyBoardRow className="row3"><KeyRow handleClick={props.handleClick}  letters={rowThreeletters} keyboardColors={props.keyboardColors} /></KeyBoardRow>
        </ul>
    );
}

export default Keyboard;
