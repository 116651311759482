import Key from "./Key";
import styled from "styled-components";

const Backspace = styled.button `
    padding: 5px 10px;
    margin-top: 10px;
    cursor: pointer;
`;

function KeyRow(props) {
    const letterList = props['letters'];
    const backspace = 'backspace';
    const enter = 'enter';
    return (
        <>
            {letterList.map((letter) => {
                if(letter === '<='){
                    return <Key handleClick={props.handleClick}  key={letter} letter={letter} backspace={backspace} keyboardColors={props.keyboardColors} />
                } else if(letter === 'enter') {
                    return <Key handleClick={props.handleClick} key={letter} letter={letter} enter={enter} keyboardColors={props.keyboardColors} />
                } else{
                    return <Key handleClick={props.handleClick}   key={letter} letter={letter} keyboardColors={props.keyboardColors} />
                }
            })
            }
        </>
    );

}

export default KeyRow;
