import styled from "styled-components";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledBoardLetter = styled.p `
    width: 60px;
    height: 60px;
    text-transform: uppercase;
    background-color: #fff;
    border: 1px solid var(--game-board-square-border-color);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 38px;
    transition: all 0.12s ease;
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}) {
        width: 42px;
        height: 42px;
    }
`;

function BoardLetter(props) {
    return <StyledBoardLetter className="board-letter">{props.letter}</StyledBoardLetter>
}

export default BoardLetter;
