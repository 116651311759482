const dictionary_helper = {
	is_valid_word: function (dictionary, todays_game, submitted_word){
		let isValid = false;
		if (submitted_word.length == 4){
			if (dictionary_helper.is_word_in_dictionary_section(
				dictionary, 
				'fourLetterWords', 
				todays_game, 
				submitted_word)
			) {
				isValid = true;
			}
		}
		if (submitted_word.length == 5){
			if (dictionary_helper.is_word_in_dictionary_section(
				dictionary, 
				'fiveLetterWords', 
				todays_game, 
				submitted_word)
			) {
				isValid = true;
			}
			
		}
		if (submitted_word.length == 6){
			if (dictionary_helper.is_word_in_dictionary_section(
				dictionary, 
				'sixLetterWords', 
				todays_game, 
				submitted_word)
			) {
				isValid = true;
			}
			
		} 
		
		return isValid;
	},
	is_word_in_dictionary_section: function (dictionary, section_name, todays_game, submitted_word) {
		submitted_word = submitted_word.toLowerCase();
		let isValid = false;
		for (let i=0; i < dictionary[section_name].length; i++){
			//console.log(dictionary[section_name][i]);
			if(submitted_word.localeCompare( dictionary[section_name][i].toLowerCase() ) === 0){
				//console.log('found match');
				//console.log(submitted_word);
				//console.log(dictionary[section_name][i]);
				isValid = true;
				break;
			}
		}
		for (let i=0; i < todays_game.words.length; i++){
			if(submitted_word.localeCompare( todays_game.words[i].toLowerCase() ) === 0) {
				isValid = true;
				break;
			}
		}
		return isValid;
	} 
}


export default dictionary_helper;
