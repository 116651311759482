import React, { useState, createContext, useEffect } from 'react';
import api_functions from '../game-api';
import Login from './login';

export default function AddAGame () {
    const [adminLoginToken, setAdminLoginToken] = useState();

    const [inputField , setInputField] = useState({
        theme: '',
        magicletter: '',
        word4: '',
        word5: '',
        word6: ''
    })
    const inputsHandler = (e) =>{
        const { name, value } = e.target;
        //console.log(name);
        //console.log(value);
        setInputField(
            (prevState) => {
                //console.log('prevState is');
                //console.log(prevState);
                let newState = { ...prevState, [name]: value, }
                //console.log('new state is ');
                //console.log(newState);
                //console.log(adminLoginToken);
                return { ...prevState, [name]: value, }
            }
        );
    }

    const submitButton = () =>{
        //console.log('entire input is:');
        //console.log(inputField);

    }

    if ( adminLoginToken !== true ) {
        return <Login setAdminLoginToken={setAdminLoginToken} />
    }
    return (
        <>
        <h1>Add A Game</h1>
        <p>Rules go here</p>
        <div>
          <input 
          type="text" 
          name="theme" 
          onChange={inputsHandler} 
          placeholder="Theme" 
          value={inputField.theme}/>
 
          <input  
          type="text"  
          name="magicletter"        
          onChange={inputsHandler}  
          placeholder="Magic Letter"       
          value={inputField.magicletter}/>

          <input  
          type="text"  
          name="word4"        
          onChange={inputsHandler}  
          placeholder="4 letter word"       
          value={inputField.word4}/>

          <input  
          type="text"  
          name="word5"        
          onChange={inputsHandler}  
          placeholder="5 letter word"       
          value={inputField.word5}/>

          <input  
          type="text"  
          name="word6"        
          onChange={inputsHandler}  
          placeholder="6 letter word"       
          value={inputField.word6}/>

          <button onClick={submitButton}>Submit Now</button>
        </div>
        </>
    );
}
