import styled from "styled-components";
import { useEffect } from 'react';


const StyledFaceBookShareButton = styled.p`
    padding: 14px 55px 14px 20px;
    color: #fff;
    font-size: 16px;
    background-color: #5c5c5c;
    border-radius: 50px;
    border: none;
    position: relative;
    cursor: pointer;
    max-width: 160px;
    margin: auto  !important; 
    &:after {
        content: url(/assets/img/share.svg);
        transform: scale(1.3);
        position: absolute;
        width: 20px;
        filter: brightness(12);
        right: 38px;
        bottom: 9px;
        cursor: pointer;
    }
`;


function FacebookShare(props) {

    useEffect(() => {
        document.querySelector('.facebook-share-button').addEventListener('click', function(){
            let listOfWordsTimes = document.querySelectorAll('.completeTime');
            let shareString = 'My ' + props.gameTitle + ' Scores' + '\n';
            let loopCounter = 0;
            let shareURL = 'https://wordthird.com/';
            listOfWordsTimes.forEach(wordsTimes => {
		//console.log('20240226 in loop');
		//console.log(wordsTimes.innerText);
                shareString = shareString + '\n' + wordsTimes.innerText.replace(
			new RegExp(props.todaysGame.words[loopCounter] + ':' , 'i'), 
			'Word ' + (loopCounter + 1) + ':'
		);
                loopCounter++;
            });
            //shareString = shareString + '\n\n' + 'Try today on ' + shareURL;
            shareString = shareString + '\n';

            if (navigator.share) {
                navigator.share({
                    title: props.gameTitle,
                    url: shareURL,
                    text: shareString
                }).then(() => {
                    //console.log('Thanks for sharing!');
                })
                .catch(console.error);
                return; 
            }
            //console.log('nav share not supported');
            navigator.clipboard.writeText(shareString);
            let copiedToClipboardElement = document.createElement('div');
            copiedToClipboardElement.classList.add('clipboard-notice')
            copiedToClipboardElement.innerHTML = "Copied Scores to Clipboard";
            let app = document.querySelector('.App');
            app.appendChild(copiedToClipboardElement);
            setTimeout(function(){
                copiedToClipboardElement.classList.add('fadeUpOut');
                document.getElementById('facebook-button').classList.add('disable');
            }, 50)
            setTimeout(function(){
                copiedToClipboardElement.classList.remove('fadeUpOut');
            }, 1000);
            setTimeout(function(){
                copiedToClipboardElement.remove();
                document.getElementById('facebook-button').classList.remove('disable');
            }, 1300);
        })
	}, []);

    return (
        <>
        <StyledFaceBookShareButton id="facebook-button" className="facebook-share-button">Share
        </StyledFaceBookShareButton>
        </>
    );

}

export default FacebookShare;
