import React, { useState, createContext, useEffect, useRef } from 'react';
import './App.css';
import Gameboard from './client/components/Gameboard/Gameboard';
import GuessArea from './client/components/GuessArea/GuessArea';
import GameCompletionBox from './client/components/GameCompletionBox/GameCompletionBox';
import GameInfo from './client/components/GameInfo/GameInfo';
import GameRules from './client/components/GameRules/GameRules';
import Keyboard from './client/components/Keyboard/Keyboard';
import Timer from './client/components/Timer/Timer';
import api_functions from './game-api';
import { getRandomEmoji } from './client/emojis';
import Nav from './client/components/Nav/Nav';
import myDictionary from './json/dictionary00';
import dictionary_helper from './client/dictionaryFunctions.js';

import styled from 'styled-components';

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from './client/constants';

export const wordLengthContext = createContext();

const currentYear = new Date().getFullYear();

let pointer = 0;
let numGuesses = 0;

function App() {

	const gameTitle = 'WordThird';

	let isTodaysGameHardcoded = false; 
	//isTodaysGameHardcoded = true; // Uncomment to test a hardcoded game

	let [todays_game, set_todays_game] = useState( {
		theme: "pets",
		magic_letter: "i",
		//words: ["pigs", "birds", "tigers"],
		words: ["pigs", "birds", "tigers"],
		ready: false
	});

	//Set initial current word's state
	let [currentWordIndex, setCurrentWordIndex] = useState(0);
	const getCurrentTargetWord = () => { 
		return todays_game['words'][currentWordIndex].toLowerCase(); 
	}
	const getCurrentTargetWordAsArray = () => {
		let word_str = getCurrentTargetWord();
		return word_str.split('');
	}

	//Initialize current word array to array of the length of the word fill with empty elements
	let startingArray = new Array( getCurrentTargetWordAsArray().length  );
	startingArray.fill('');

	//Set initial word's state
	let [gameBoardWord, setGameBoardWord] = useState(startingArray);

	//Set emoji path
	let [emojiImage, setEmojiImage] = useState(''); 

	//Set input error state
	let [inputError, setInputError] = useState('');	

	const isGameCompleteRef = useRef(false);

	let [hasPlayerStartedGame, setHasPlayerStartedGame] = useState(false);
	let [doWeShowRules, setDoWeShowRules] = useState(true);
        let [doWeStopTimer, setdoWeStopTimer] = useState(false);

	let [doWeShowGameCompletionBox, setDoWeShowCompletionBox] = useState(false);


	const blankKeyboardColors = {
		lettersNotInTargetWord: "",
		lettersInTargetWordCorrectSpot: "",
		lettersInTargetWordWrongSpot: ""
	}
	let [keyboardColors, setKeyboardColors] = useState (blankKeyboardColors);


	// This useEffect code loads todays game from the server
	useEffect(() => {
		document.body.addEventListener("keyup", (event) => {
			let isLetter = checkKeyInputIsLetter(event.key);
			if (event.key == "Backspace") {
				removeLetterFromGameBoard(letter);
			} else if (event.key == "Enter") {
				submitGuess();
			}
			else if (isLetter == true) {
				var letter = event.key;
				letter = letter.toLowerCase();
				addLetterToGameBoard(letter);
			}
		})
		let game_json_promise = api_functions.get_todays_game_v1();
		game_json_promise.then( (result) => {
			api_functions.set_game_cookies(result);
			//console.log('All cookies: '); 
			//console.log(api_functions.get_cookie());
			let milliseconds_since_start = performance.now();
			let game = result.game;
			let tempGame = {
				theme: game.theme,
				magic_letter: game.magicletter,
				words: [game.word4, game.word5, game.word6],
				ready: true
			}
			//if todays game is hardcoded
			if (isTodaysGameHardcoded) {
				tempGame = {
					theme: todays_game.theme, 
					magic_letter: todays_game.magic_letter,
					words: todays_game.words,
					ready: true
				}
			}
			if (milliseconds_since_start < 2000) {
				setTimeout(function () {
					set_todays_game(tempGame);
					todays_game = tempGame;
					//console.log(todays_game);
				}, 1000);
			} else {
				set_todays_game(tempGame);
				todays_game = tempGame;
				//console.log(todays_game);
			}
		} );

	}, []);

	const isTheGameCompleted = () => {
		return isGameCompleteRef.current;
	}

	//Adds letters to game board boxes when on-screen letter is clicked
	const addLetterToGameBoardByButton = event => {
		//console.log(isTheGameCompleted());
		if ( isTheGameCompleted() ){
			return;
		}
		let letter = event.target.dataset.letter;
		let isLetter = checkKeyInputIsLetter(letter);
		if (isLetter == true) {
			addLetterToGameBoard(letter);
		}
	}

	const addLetterToGameBoard = (letter) => {
		//console.log(isTheGameCompleted());
		if ( isTheGameCompleted() ){
			return;
		}
		if (pointer <= getCurrentTargetWord().length - 1) {
			let newWordArray = gameBoardWord;
			newWordArray[pointer] = letter;
			setGameBoardWord([...newWordArray]);
			pointer++;
		}
	}

	//Removes letters from game board boxes when on-screen backspace is clicked
	const removeLetterFromGameBoard = event => {
		if (pointer > 0) {
			pointer--;
			let newWordArray = gameBoardWord;
			newWordArray[pointer] = ' ';
			setGameBoardWord([...newWordArray]);
		}
	}

	//used to get the user's submitted guess as a string
	const stringArrayToString = (word_as_array) => {
		let str = '';
		for (let i = 0; i < word_as_array.length; i++){
			str += word_as_array[i];
		}
		return str;
	}

	const submitGuess = () => {
		let isWordBlank = checkForBlankLetters(gameBoardWord);
		if (isWordBlank == true) {
			let errorMessage = "Please fill in the entire word before submitting.";
			setInputError(errorMessage);
			document.getElementById('error').classList.add('show');
		}
		else if (!dictionary_helper.is_valid_word(myDictionary, todays_game, stringArrayToString (gameBoardWord) )){
			//console.log('WORD IS NOT IN DICT');
			let errorMessage = "Sorry, we do not recognize the word you've guessed. Please try another one.";
			setInputError(errorMessage);
			document.getElementById('error').classList.add('show');
		}
		else {
			document.getElementById('error').classList.remove('show');
			compareGuessToWord(gameBoardWord);
		}
	}

	const haveAllInstancesOfLetterBeenMarkedAsCorrect = (letter, letterSlotStatusArray, targetWordAsArray) => {
		let letterOccuranceCount = 0;
		let letterCorrectCount = 0;
		let letterOccuranceIndexList = new Array();
		let boolResult = false;

		for(let i = 0; i < targetWordAsArray.length; i++) {
			if (letter === targetWordAsArray[i] ){
				letterOccuranceCount++;
				letterOccuranceIndexList.push(i);
			}
		}
		for(let i = 0; i < letterOccuranceIndexList.length; i++) {
			let letterIndex = letterOccuranceIndexList[i];
			if (letterSlotStatusArray[letterIndex] === 'correct'){
				letterCorrectCount++;
			}
		}
		if (letterCorrectCount === letterOccuranceCount) {
			boolResult = true;
		}
		return boolResult;	
	}
	const updateLetterSlotStatusArrayToAccountForTooManyLettersInWordButWrongSpot = (letter, letterSlotStatusArray, targetWordAsArray, guess) => {
		//console.log('20240223 - checking on letter ' + letter);
		//console.log(guess);
		let numOfOccurencesInTargetWord = 0;
		let numOfOccurencesInGuess = 0;
		for(let i = 0; i < targetWordAsArray.length; i++){
			if (targetWordAsArray[i] === letter){
				numOfOccurencesInTargetWord++;
			}
		}
		//console.log('occurences in target is ' + numOfOccurencesInTargetWord  );
		for(let i = 0; i < targetWordAsArray.length; i++){
			if (guess[i] === letter && letterSlotStatusArray[i] === 'in-word'){
				numOfOccurencesInGuess++;
				//console.log('Occurences in guess is ' + numOfOccurencesInGuess );
				if (numOfOccurencesInGuess > numOfOccurencesInTargetWord) {
					letterSlotStatusArray[i] = '';
				}
			}
		}
		//console.log(letterSlotStatusArray);
		return  letterSlotStatusArray;

	}

	const compareGuessToWord = (guess) => {
		//console.log('In compareGuesstoWord -  guess is ' + guess);
		numGuesses++;
		let wordBuilder = '';
		let guessedWordDiv = document.createElement("div");

		let letterSlotStatuses = new Array( getCurrentTargetWordAsArray().length );
		for (let i = 0; i < letterSlotStatuses.length; i++) {
			letterSlotStatuses[i] = '';
		}
		guessedWordDiv.classList.add('guessed-word');
		//Build guessed word in HTML
		wordBuilder = guessedWordDiv;
		let newKeyboardColors = {
			lettersNotInTargetWord: keyboardColors.lettersNotInTargetWord,
			lettersInTargetWordCorrectSpot: keyboardColors.lettersInTargetWordCorrectSpot,
			lettersInTargetWordWrongSpot: keyboardColors.lettersInTargetWordWrongSpot
		}
		for (const currentGuessesLetterPosition in guess) {
			let currentGuessesLetter = guess[currentGuessesLetterPosition];
			//Checking if guessed letter exists in current word.
			for (const currentWordsLetterPosition in getCurrentTargetWordAsArray() ) {
				if (currentGuessesLetter == getCurrentTargetWordAsArray()[currentWordsLetterPosition]) {
					//Checking if guessed letter exists in the right place.
					if (currentGuessesLetterPosition == currentWordsLetterPosition) {
						letterSlotStatuses[currentGuessesLetterPosition] = 'correct';
						newKeyboardColors.lettersInTargetWordCorrectSpot = newKeyboardColors.lettersInTargetWordCorrectSpot.concat(currentGuessesLetter);
					}

				}
			}
		}
		for (const currentGuessesLetterPosition in guess) {
			let currentGuessesLetter = guess[currentGuessesLetterPosition];
			let guessedLetterHTML = document.createElement("p");
			guessedLetterHTML.classList.add('letter-guess');
			guessedLetterHTML.dataset.status = '';
			for (const currentWordsLetterPosition in getCurrentTargetWordAsArray() ) {
				if (currentGuessesLetter !== getCurrentTargetWordAsArray()[currentWordsLetterPosition]) {
					continue;
				}
				if (letterSlotStatuses[currentGuessesLetterPosition] !== 'correct' ) {
					if (haveAllInstancesOfLetterBeenMarkedAsCorrect(currentGuessesLetter,letterSlotStatuses,getCurrentTargetWordAsArray())){
						//do we need to update keyboard colors?
						continue;
					}
					letterSlotStatuses[currentGuessesLetterPosition] = 'in-word';
					newKeyboardColors.lettersInTargetWordWrongSpot = newKeyboardColors.lettersInTargetWordWrongSpot.concat(currentGuessesLetter);
			 	}
			}
			if ( letterSlotStatuses[currentGuessesLetterPosition] !== 'correct' 
			&&   letterSlotStatuses[currentGuessesLetterPosition] !== 'in-word' ){
				newKeyboardColors.lettersNotInTargetWord = newKeyboardColors.lettersNotInTargetWord.concat(guess[currentGuessesLetterPosition]);
			} else {
				letterSlotStatuses = updateLetterSlotStatusArrayToAccountForTooManyLettersInWordButWrongSpot(currentGuessesLetter,letterSlotStatuses,getCurrentTargetWordAsArray(),guess);
				guessedLetterHTML.dataset.status = letterSlotStatuses[currentGuessesLetterPosition];
			}
			//letterSlotStatuses = updateLetterSlotStatusArrayToAccountForTooManyLettersInWordButWrongSpot(currentGuessesLetter,letterSlotStatuses,getCurrentTargetWordAsArray(),guess);
			guessedLetterHTML.innerHTML = guess[currentGuessesLetterPosition];
			wordBuilder.appendChild(guessedLetterHTML);
			
		}
		//console.log('dictionary is below');
		//console.log(myDictionary);
		//console.log('newKeyboardColors below');
		//console.log(newKeyboardColors);


		setKeyboardColors(newKeyboardColors);
		keyboardColors = newKeyboardColors;
		
		//console.log('keyboard colors below OG');
		//console.log(keyboardColors);
		
		document.querySelector('.guess-area').append(wordBuilder);
		resetGameBoardWord();

		//Check if the round is over by checking if all letters are correct
		let currentGuess = document.querySelector('.guessed-word:last-child').querySelectorAll('.letter-guess');
		let countNotCorrect = 0;
		for (const guess of currentGuess) {
			if (guess.dataset['status'] !== 'correct') {
				countNotCorrect++;
			}
		}
		
		if (countNotCorrect === 0) {
			//Add time to completion box when word is found
			addTimeToCompletionBoard();
			//Add correct word to correct word list
			addCorrectWord(currentGuess);
			//Get random happy emoji
			let emojiPath = getRandomEmoji('happy');
			setEmojiImage(emojiPath);

			if(currentWordIndex < 2){
				//Remove all guesses from board when correct word is guessed.
				let allGuessedWords = document.querySelectorAll('.guessed-word');
				setTimeout(function(){
					allGuessedWords.forEach(guessedWord => {
						guessedWord.remove();
					});
				}, 750)
				//Prepare index for next word
				setCurrentWordIndex(currentWordIndex + 1);
				currentWordIndex = currentWordIndex + 1;
				//Initialize current word array to array of the length of the word fill with empty elements
				let startingArray = gameBoardWord;
				startingArray.push('');
				setGameBoardWord(startingArray);
				setKeyboardColors(blankKeyboardColors);
				keyboardColors = blankKeyboardColors;	
			}
			else{
                                // GAME COMPLETE HERE
				//document.querySelector('.game-completion-box-wrapper').classList.add('show');
				isGameCompleteRef.current = true;
				setDoWeShowCompletionBox(true);
                                setdoWeStopTimer(true);
			}
		} else {
			//Get random sad emoji
			let emojiPath = getRandomEmoji('sad');
			setEmojiImage(emojiPath);
			countNotCorrect = 0;
		}

		//Temporarily show emoji
		setTimeout(function(){
		    document.getElementById('emoji').classList.add('show');
		}, 50)
		setTimeout(function(){
			document.getElementById('emoji').classList.remove('show');
		}, 1200)
	}

	//Check for empty places in gameBoardWord
	const checkForBlankLetters = (guessedWord) => {
		let numBlanks = 0;
		for (const char in guessedWord) {
			if (guessedWord[char] === '' || guessedWord[char] === ' ') {
				numBlanks++;
			}
		}

		if (numBlanks !== 0) {
			return true;
		}
		else {
			return false;
		}
	}


	const checkKeyInputIsLetter = (letter) => {
		return letter.length === 1 && (/[a-zA-Z]/).test(letter);
	}

	const resetGameBoardWord = () => {
		let gameBoardWordToBeCleared = gameBoardWord;
		for (const letter in gameBoardWordToBeCleared) {
			gameBoardWordToBeCleared[letter] = '';
		}
		pointer = 0;
		setGameBoardWord([...gameBoardWordToBeCleared]);
	}

	function addTimeToCompletionBoard(){
		let completedWordIndex = currentWordIndex + 1;
		let currentMinutes = document.getElementById('minutes').innerHTML;
		let currentSeconds = document.getElementById('seconds').innerHTML;
		let currentTime = currentMinutes + ':' + currentSeconds;
		document.getElementById('time'+ completedWordIndex).innerHTML += currentTime + ', <strong>Attempts: </strong>' + numGuesses;
	}

	function addCorrectWord(correctWord){
		let completedWordIndex = currentWordIndex + 1;
		let correctWordBuilder = '';
		for (const letter of correctWord) {
			correctWordBuilder += letter.innerHTML;
		}
		document.getElementById('correctWord'+ completedWordIndex).innerHTML += correctWordBuilder + '<span class="attempts background-color-1 text-color-1">' + numGuesses + '</span>';
		numGuesses = 0;
	}
	const closeGameRules = () => {
		setDoWeShowRules(false);	
		setHasPlayerStartedGame(true);
	}

	if (!todays_game.ready){
		return (
			<div id="loading-animation" className="App"><img src="/assets/img/Spin-2s-351px.svg" /></div>
		);
	}

	return (
		<div className="App">
            {/* Gradient Overlay for Mobile */}
            <GradientOverlay />
            
            {/* Header */}
			<Nav gameTitle={gameTitle} doWeShowRules={doWeShowRules} setDoWeShowRules={setDoWeShowRules} setHasPlayerStartedGame={setHasPlayerStartedGame} />

            {/* Modal/Popup */}
			<GameRules startFunction={closeGameRules} showRules={doWeShowRules} gameTitle={gameTitle} />
            {/* Main Body Wrapper */}
            <BodyWrap>
                <LeftCol>
                    <GameInfo todaysGame={todays_game} hasPlayerStartedGame={hasPlayerStartedGame} />
                </LeftCol>
                <MiddleCol>
                    <GuessArea emojiImage={emojiImage}/>
                    <Gameboard gameBoardWord={gameBoardWord} error={inputError} />
                    <wordLengthContext.Provider value={[getCurrentTargetWordAsArray().length - 1]}>
                        <Keyboard keyboardColors={keyboardColors} handleClick={[addLetterToGameBoardByButton, removeLetterFromGameBoard, submitGuess]} />
                    </wordLengthContext.Provider>
                </MiddleCol>
                <RightCol>
                    <Timer todaysGame={todays_game} showRules={doWeShowRules} doWeStopTimer={doWeStopTimer} />
                </RightCol>
            </BodyWrap>

            {/* Modal/Popup */}
			<GameCompletionBox gameTitle={gameTitle} todaysGame={todays_game} 
			showGameCompletionBox={doWeShowGameCompletionBox} showGameCompletionBoxSetter={setDoWeShowCompletionBox} />
            
            <CopyrightText>
                <a href="https://tagonline.com" target="_blank" rel="noopener noreferrer">TAG Online</a> &copy; {currentYear}
            </CopyrightText>
		</div>
	);
}

export default App;

//////////////////////////////
// Styled Components
//////////////////////////////

// Gradient Overlay for Mobile
const GradientOverlay = styled.div`
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        content: '';
        background: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,255,255,0) 100%);
        z-index: 3;
        width: 100%;
        position: absolute;
        top: 0%;
        left: 0;
        height: 65%;
    }
`

const CopyrightText = styled.label`
    color: #CCC;
    font-size: 12px;
    text-align: center;
    padding-bottom: 8px;
    
    a {
        color: #CCC;
        text-decoration: none;
        transition: all 0.18s ease;
    }
    a:hover {
        color: #007BC2;
    }
`

//////////////////////////////
// Div Wrappers
//////////////////////////////

// BodyWrap is the main 3 column wrapper
const BodyWrap = styled.div`
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        flex-wrap: wrap;
        position: relative;
    }
    `;

const LeftCol = styled.div`
    flex: 2;
    padding-top: 42px;
    padding-left: 42px;
    box-sizing: border-box;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        flex: unset;
        padding-top: 16px;
        padding-left: 16px;
        width: 50%;
        order: 1;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }
    `

const MiddleCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 3;
    box-sizing: border-box;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        flex: unset;
        width: 100%;
        order: 3;
        position: absolute;
        left: 0;
        bottom: 32px;
        z-index: 2;
    }
    `;

const RightCol = styled.div`
    flex: 2;
    box-sizing: border-box;
    padding-top: 42px;
    padding-right: 42px;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        flex: unset;
        padding-top: 10px;
        padding-right: 16px;
        width: 50%;
        order: 2;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 4;
    }
`
