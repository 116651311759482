import styled from "styled-components";
import React, { useState, createContext, useRef, useEffect } from 'react';

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledTimer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 48px;
    font-family: 'Lato', sans-serif;

    background-color: rgba(255,255,255,0.8);
    /* background-color: green; */
    @media screen and (max-width: ${MOBILE_SMALLER_BREAKPOINT}){
        font-size: 2rem;
    }
`;

const StyledTimerDigits = styled.p`
    margin-top: 0;
    margin-bottom: 0;
    line-height: .9;
    position: relative;
`;

function pad ( val ) { return parseInt(val) > 9 ? val : "0" + val; }

function Timer(props) {
    let [timerSeconds, setTimerSeconds] = useState("00");
    let [timerMinutes, setTimerMinutes] = useState("00");

    const Ref = useRef(null);

    const startTimer = () => {
        // If Ref.current already has a setInterval, then exit
        if(Ref.current != null){
            return
        }
        Ref.current = setInterval( () => {
            setTimerSeconds(seconds => {
                let seconds_as_int = parseInt(seconds);
                let next_second_as_int = seconds_as_int + 1;
                if (seconds_as_int >= 59){ 
                    setTimerMinutes( minutes => {
                        let minutes_as_int = parseInt(minutes);
                        let next_minute_as_int = minutes_as_int + 1;
                        return pad( next_minute_as_int.toString()  );
                    });
                    return "00";
                }
                
                return pad( next_second_as_int.toString() );
            });
        }, 1000 );
    }

    const pauseTimer = () => {
        clearInterval(Ref.current);
    }

    if (props.doWeStopTimer){
        //console.log('in timer.js timer can be stopped');
        pauseTimer();
    }
    useEffect( () => {
        //console.log('this thing on?');
        if (props.todaysGame.ready && props.showRules === false) {
            startTimer();
        }
    }, [props.todaysGame.ready, props.showRules])
	
    return (
        <>
        <StyledTimer className="timer text-color-0">
            <StyledTimerDigits id="minutes">{timerMinutes}</StyledTimerDigits>:
            <StyledTimerDigits id="seconds">{timerSeconds}</StyledTimerDigits>
        </StyledTimer>
        </>
    );

}

export default Timer;
