import styled from "styled-components";
import BoardLetter from "./Boardletter";
import uuid from 'react-uuid'

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledGameBoard = styled.div`
    display: flex;
    justify-content: center;
    grid-gap: 15px;
    position: relative;
    width: 100%;
    transition: all 0.12s ease;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        grid-gap: 7px;
    }
`;

function Gameboard(props) {
    return (
        <>
        <StyledGameBoard className="game-board">
            { 
            Object.values(props.gameBoardWord).map((letter) => { 
                return <BoardLetter key={uuid()} letter={letter} />;
            })
            }
            <p id="error">{ props.error }</p>
        </StyledGameBoard>
        </>
    );

}

export default Gameboard;