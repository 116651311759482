import styled from "styled-components";
import { RandomEmoji } from "../../emojis";

import { MOBILE_BREAKPOINT, MOBILE_SMALLER_BREAKPOINT } from "../../constants";

const StyledGuessArea = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    max-height: calc(40% - 50px);
    row-gap: 16px;
    height: 100%;
    background: #FFFFFF;
    max-width: 350px;
    width: 100%;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        height: 200%;
        top: -100%;
        left: 0;
        width: 125%;
        background: linear-gradient(180deg, #ffffff 0%, rgba(28,28,28,0) 100%);
    }
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        max-width: min(80vw, 400px);
        max-width: min(80dvw, 400px);
    }
`;

function GuessArea(props) {

    return (
        <>
        <StyledGuessArea className="guess-area">
            <RandomEmoji emojiPath={props.emojiImage}></RandomEmoji>
        </StyledGuessArea>
        </>
    );

}

export default GuessArea;
