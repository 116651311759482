import styled from "styled-components";
import FacebookShare from "../FacebookShare/FacebookShare";

const StyledGameCompletionBox = styled.div`
    padding: 10px 40px 30px;;
    background-color: #fff;
    box-shadow: 0px 0px 7px #000;
`;

const StyledGameCompletionBoxWrapper = styled.div`
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .4);
    z-index: 1000;
`;


function GameCompletionBox(props) {
    let gameCompletionBoxWrapperClasses = 'game-completion-box-wrapper';
    if (props.showGameCompletionBox){
	gameCompletionBoxWrapperClasses += ' show';
    }
    return (
        <>
        <StyledGameCompletionBoxWrapper className={ gameCompletionBoxWrapperClasses }>
            <StyledGameCompletionBox className="game-completion-box">
		<div id="close-game-completion-box-wrapper">
			<div id="close-game-completion-box" onClick={() => {props.showGameCompletionBoxSetter(false)} }>X</div>
		</div>
                <h2>Congratulations, you've won!</h2>
                <div className="times-wrapper">
                    <p className="completeTime" id="time1"><strong>{ props.todaysGame.words[0] }: </strong></p>
                    <p className="completeTime" id="time2"><strong>{ props.todaysGame.words[1] }: </strong></p>
                    <p className="completeTime" id="time3"><strong>{ props.todaysGame.words[2] }: </strong></p>                    
                </div>
                <FacebookShare gameTitle={props.gameTitle} todaysGame={props.todaysGame} />
            </StyledGameCompletionBox>
        </StyledGameCompletionBoxWrapper>
        </>
    );

}

export default GameCompletionBox;
