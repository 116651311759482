export const rulesData = [
    {
        'rule': "Solve three mystery words related to today's theme as fast as you can. Words are 4, 5 & 6 letters respectively."
    },
    {
        'rule': "One common letter will appear in all 3 words, called the Magic Letter."
    },
    {
        'rule': "Once you make a guess, the letters will be colored according to accuracy. Green indicates the correct letter in the right location. Yellow is a correct letter in the wrong location. No color indicates the letter is not in the word at all."
    },
    {
        'rule': "The game clock starts when you click PLAY."
    },
    {
        'rule': "Good luck!"
    }
]
